<template>
  <div v-if="loaded">
    <div v-if="reviewFlow">
      <div class="row row-cards mb-3">
        <div class="col-4">
          <div class="card card-sm">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="bg-yellow text-white avatar"
                    ><!-- Download SVG icon from http://tabler-icons.io/i/users -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path
                        d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"
                      ></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                      <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
                    </svg>
                  </span>
                </div>
                <div class="col">
                  <div class="font-weight-bold">
                    {{ total_members }}
                  </div>
                  <div class="small text-muted">total members</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card card-sm">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="bg-blue text-white avatar">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-check"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="1"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M5 12l5 5l10 -10"></path>
                    </svg>
                  </span>
                </div>
                <div class="col">
                  <div class="font-weight-medium" v-if="counts">
                    {{ counts.started }}
                  </div>
                  <div class="text-muted small">reviews started</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card card-sm">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span class="bg-green text-white avatar">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-checks"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="1"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M7 12l5 5l10 -10"></path>
                      <path d="M2 12l5 5m5 -5l5 -5"></path>
                    </svg>
                  </span>
                </div>
                <div class="col">
                  <div class="font-weight-bold" v-if="counts">
                    {{ counts.complete }}
                  </div>
                  <div class="small text-muted">reviews completed</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3" v-if="reviews.length > 0">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Reviews</h3>
            </div>
            <reviews-table :reviews="reviews"></reviews-table>
          </div>
        </div>
      </div>

      <div class="row row-deck row-cards">
        <div class="col-8">
          <cohort-member-table
            v-if="
              cohort && $auth.planIncludes('request-reviews', $auth.highestPlan)
            "
            :cohort="cohort"
            @reload-counts="reloadCounts"
          ></cohort-member-table>
          <div class="w-100" v-else>
            <upgrade-box>
              <p>
                <strong>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-alert-triangle text-red"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 9v2m0 4v.01"></path>
                    <path
                      d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"
                    ></path>
                  </svg>
                  Want to automatically request reviews from your customers?
                </strong>
              </p>
            </upgrade-box>
          </div>
        </div>
        <div class="col-4">
          <div class="card" data-v-step="2">
            <div class="card-header d-flex">
              <h3 class="card-title">Get More Reviews</h3>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <label class="form-label"> Share your review URL </label>
                <input
                  type="text"
                  :value="`${business_chatter_url}review/start/${this.reviewFlow.slug}`"
                  class="form-control"
                  @focus="$event.target.select()"
                  readonly=""
                />
                <a
                  :href="`${business_chatter_url}review/start/${this.reviewFlow.slug}`"
                  target="_blank"
                  @click.prevent="copyUrl"
                >
                  Click to copy
                </a>

                <transition name="fade">
                  <span v-if="copied" class="small text-muted">Copied!</span>
                </transition>
              </div>
              <div
                class="mb-4"
                v-if="$auth.planIncludes('rep-mgmt-qr', $auth.highestPlan)"
              >
                <label class="form-label"> Share your QR Code </label>
                <img style="max-width: 156px" :src="this.qrCode" />
              </div>
              <div class="mb-4" v-else>
                <label class="form-label"> Share your QR Code </label>
                <upgrade-box>
                  <p>
                    <strong>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-alert-triangle text-red"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M12 9v2m0 4v.01"></path>
                        <path
                          d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"
                        ></path>
                      </svg>
                      Your plan doesn't include a review QR code
                    </strong>
                  </p>
                </upgrade-box>
              </div>

              <div class="alert alert-info" role="alert">
                <div class="d-flex">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-bulb alert-icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7"
                      ></path>
                      <path
                        d="M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3"
                      ></path>
                      <line x1="9.7" y1="17" x2="14.3" y2="17"></line>
                    </svg>
                  </div>
                  <div>
                    <h4 class="alert-title">Pro Tip</h4>
                    <div class="text-muted">
                      Most users will add their review URL to their email
                      signature to get more reviews.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<script>
import { formatDistanceToNow, parseISO } from "date-fns";

import ReputationManagementService from "../../services/ReputationManagementService";
import CohortMemberTable from "./Cohort/CohortMemberTable.vue";
import QRCode from "easyqrcodejs";
import ReviewsTable from "./ReviewFlow/ReviewsTable.vue";
import UpgradeBox from "../../components/Upgrade/UpgradeBox.vue";

import TrackingService from "@/services/TrackingService";

export default {
  components: { ReviewsTable, CohortMemberTable, UpgradeBox },
  name: "reputation.customer-survey",
  props: {
    tutorial: Boolean,
  },
  data: () => {
    return {
      reviewFlow: null,
      qrCode: null,
      cohorts: [],
      cohort: null,
      reviews: [],
      activeCohort: null,
      company: null,
      counts: null,
      loaded: false,
      business_chatter_url: ReputationManagementService.business_chatter_url,
      copied: false,
    };
  },
  computed: {
    total_members() {
      return this.cohorts.reduce((acc, cur) => acc + cur.member_count, 0);
    },
  },
  methods: {
    relativeDate(date) {
      return formatDistanceToNow(parseISO(date));
    },
    async reloadCohorts() {
      this.cohorts = await ReputationManagementService.getReviewFlowCohorts(
        this.$auth.activeCompany,
        this.reviewFlow.id
      );
      this.cohort = this.cohorts[0];
    },
    copyUrl() {
      TrackingService.track("review url copied");
      this.copied = true;
      navigator.clipboard
        .writeText(
          `${this.business_chatter_url}review/start/${this.reviewFlow.slug}`
        )
        .then(() => {});
      setTimeout(() => {
        this.copied = false;
      }, 1500);
    },
    generateQRCode(image) {
      return new Promise((resolve) => {
        if (!this.reviewFlow) {
          return;
        }
        var options = {
          text: `${this.business_chatter_url}review/start/${this.reviewFlow.slug}`,
          width: 1024,
          height: 1024,
          dotScale: 0.6,
          dotScaleTiming: 0.75, // Dafault for timing block , must be greater than 0, less than or equal to 1. default is 1
          dotScaleA: 0.75, // Dafault for alignment block, must be greater than 0, less than or equal to 1. default is 1
          crossOrigin: "anonymous",
          onRenderingEnd: (_, data) => resolve(data),
        };
        if (image) {
          options.logo = image;
        }
        try {
          new QRCode(document.createElement("div"), options);
        } catch {
          delete options.logo;
          new QRCode(document.createElement("div"), options);
        }
      });
    },
    async reloadCounts() {
      this.counts = await ReputationManagementService.getReviewFlowCounts(
        this.$auth.activeCompany,
        this.reviewFlow.id
      );
      await this.reloadCohorts();
    },
    slugify(text) {
      return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/[^\w-]+/g, "") // Remove all non-word chars
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
    },
    async autoSetup() {
      let setup = {
        style: "them",
        survey: {
          name: this.company.name,
          slug: this.slugify(`${this.company.name} reviews`),
        },
        responses: { workflow: "cadence" },
        contacts: { customers: [] },
      };
      await ReputationManagementService.autoSetup(
        this.$auth.activeCompany,
        setup
      );
      await this.reloadData();
    },
    async reloadData() {
      this.loaded = false;
      let flows = await ReputationManagementService.getReviewFlows(
        this.$auth.activeCompany
      );
      this.company = await ReputationManagementService.getCompany(
        this.$auth.activeCompany
      );
      if (flows.length > 0) {
        this.reviewFlow = await ReputationManagementService.getReviewFlow(
          this.$auth.activeCompany,
          flows[0].id
        );
        await this.reloadCohorts();

        await this.reloadCounts();
        this.reviews = await ReputationManagementService.getReviewFlowReviews(
          this.$auth.activeCompany,
          this.reviewFlow.id
        );
        this.qrCode = await this.generateQRCode(null);
      } else {
        await this.autoSetup();
      }
      this.loaded = true;

      try {
        this.qrCode = await this.generateQRCode(this.company.image);
      } catch {
        this.qrCode = await this.generateQRCode(null);
      }
    },
  },
  async mounted() {
    await this.reloadData();
  },
};
</script>
